import React, { Suspense, useEffect } from 'react';
import { useRoutes } from "react-router-dom";

// Lazy loading the components
const Phase3Tool  = React.lazy(() => import('../pages/Admin/Phase3Tool/Phase3Tool'));
const PageNotFound = React.lazy(() => import('../pages/NotFound/NotFound'));

/**
 * This hook is used to set custom page title dynamically in browser tab
 * @param {*} param0
 * @returns 
 */
const DocumentTitle = ({ title, children }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
    return <>{children}</>;
};


/**
 * This component is used to manage all application routes
 * @returns
 */

function RootRouter() {
    let routes = [];

    routes.push(
        {
            path: "/",
            element: (
                <Suspense fallback={<div></div>}>
                    <DocumentTitle title="Phase III Tool - Forward Edge-AI">
                        <Phase3Tool />
                    </DocumentTitle>
                </Suspense>
            ),
        }
    );

    routes.push({
        path: "*",
        element: (
            <Suspense fallback={<div></div>}>
                <DocumentTitle title="404 - Forward Edge-AI">
                    <PageNotFound />
                </DocumentTitle>
            </Suspense>
        ),
    })
    return useRoutes(routes);
}

export default RootRouter;
